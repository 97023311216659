import React from "react"
import IndexPageTemplate from "../../templates/pages"
import { graphql } from "gatsby"

export const query = graphql`
  query IndexPageQueryEn {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      surgeonName: surgeonName_en
      title: title_en
      description: description_en
      keywords: keywords_en
    },
    reviews: allSanityReview {
      nodes {
        name_en
        reviewMessages_en
        reviewStars
      }
    }
    modelPhotos: allSanityMainPagePhoto {
      nodes {
        photo {
          asset {
            gatsbyImageData
          }
          alt
        }
      }
    }
    surgeryTypes: allSanitySurgeryType(filter: {}, limit: 6, sort: {order: ASC, fields: orderRank}) {
      nodes {
        title_en
        Icon {
          asset {
            gatsbyImageData
          }
        }
        riveAnimation {
          asset {
            url
          }
        }
        _id
        link
        desc_en {
          children {
            text
          }
        }
      }
    }
    elena: file(base: {eq: "Elena.png"}) {
      childImageSharp {
        gatsbyImageData
      }
    }
  } 
`

const IndexPage = props => {
  const { data } = props;

  return (
    <IndexPageTemplate data={data} isEn={true} />
  )
}

export default IndexPage
